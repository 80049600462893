.footer {
  background-color: #0c1838;
}

.footer .wrapper .footer_container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 11% 0%;
}

.footer .wrapper .footer_container .footer_header {
  display: flex;
  justify-content: center;
  align-items: center;
}

.footer .wrapper .footer_container .footer_header span {
  color: #ffffff;
  font-family: "Poppins";
  text-align: center;
  font-weight: 500;
  font-size: 60px;
  line-height: 96px;
  padding-bottom: 30px;
}

.footer_container .form_section form {
  display: flex;
  justify-content: space-around;
}

.footer_container .form_section form .input_fields {
  flex-basis: 40%;
}

.footer_container .form_section form .input_fields .input_container {
  width: 98%;
  position: relative;
  margin-bottom: 20px;
}

.footer_container .form_section form .input_fields .input_container label {
  position: absolute;
  transform: translateY(-25px);
  transition: transform 0.3s ease;
  left: 10px;
  bottom: -15px;
  color: rgba(255, 255, 255, 0.5);
  font-family: "Poppins";
  font-weight: 400;
  font-size: 22px;
  letter-spacing: -0.48px;
}

.footer_container .form_section form .input_fields .input_container input {
  width: 100%;
  height: 30px;
  font-size: 18px;
  font-family: "Poppins";
  transition: 0.6s;
  border: none;
  border-bottom: 1px solid #c4cbf5;
  background: transparent;
  color: #fff;
}

.footer_container
  .form_section
  form
  .input_fields
  .input_container
  input:focus {
  outline: none;
  border-bottom: 1px solid #c4cbf5;
}

form .input_fields .input_container small {
  position: absolute;
  top: 100%;
  color: #ff0000;
}

.animation label {
  transform: translateY(-55px) !important;
  font-size: 15px !important;
  text-transform: uppercase !important;
}

.animation-color label {
  color: rgba(255, 255, 255, 0.5);
}

.footer_container .form_section form .textarea_field {
  flex-basis: 50%;
}

.footer_container .form_section form .textarea_field textarea {
  border: 1px solid #f2f5f7;
  background: transparent;
  border-radius: 10px;
  appearance: none;
  width: 97%;
  font-family: "Poppins";
  height: 310px;
  font-size: 22px;
  color: #fff;
}

.footer_container .form_section form .textarea_field textarea::placeholder {
  color: rgba(255, 255, 255, 0.5);
  font-family: "Poppins";
  font-weight: 400;
  font-size: 22px;
  line-height: 40px;
  letter-spacing: -0.48px;
  padding: 2%;
}

form .textarea_field small {
  color: #ff0000;
}

form .input_fields .button_section .privacy {
  width: 380px;
}

form .input_fields .button_section .privacy p {
  font-size: 18px;
  font-family: "Poppins";
  font-weight: 400;
  line-height: 40px;
  letter-spacing: -0.48px;
  color: rgba(255, 255, 255, 0.5);
}

form .input_fields .button_section .privacy p span {
  font-size: 18px;
  font-family: "Poppins";
  font-weight: 600;
  line-height: 40px;
  letter-spacing: -0.48px;
  color: rgba(171, 189, 255, 0.5);
}

.footer_container .form_section form .input_fields .button_section {
  display: flex;
  justify-content: space-between;
  padding-top: 25px;
}

form .input_fields .button_section .submit_button .btn_submit {
  width: 242px;
  height: 65px;
  border-radius: 16px;
  font-size: 22px;
  line-height: 24px;
  font-weight: 700;
  font-family: "Poppins";
  letter-spacing: -0.4px;
  color: #06a9ca;
  background: linear-gradient(180deg, #334679 0%, #162561 100%);
  border: none;
  box-shadow: 0px 16px 24px 0px #00000040,
    /* bottom shadow */ 0 -25px 45px -5px rgba(40, 51, 111, 0.7);
  transition: transform 0.2s;
}

form .input_fields .button_section .submit_button .btn_submit:hover {
  transform: scale(1.05);
  transition: 0.3s;
  box-shadow: 0px 16px 24px 0px #00000040,
    /* bottom shadow */ 0 -25px 45px -5px rgba(40, 51, 111, 0.7);
}

form .textarea_field .submit_button .btn_submit {
  width: 242px;
  height: 65px;
  border-radius: 16px;
  font-size: 22px;
  line-height: 24px;
  font-weight: 700;
  letter-spacing: -0.4px;
  color: #06a9ca;
  background: linear-gradient(180deg, #334679 0%, #162561 100%);
  border: none;
  box-shadow: 0px 16px 24px 0px #00000040,
    /* bottom shadow */ 0 -25px 45px -5px rgba(40, 51, 111, 0.7);
  transition: transform 0.2s;
  display: none;
}

.WWDFooterAnimate {
  position: relative;
  transform: translateY(250px);
  opacity: 0;
  transition: opacity 0.6s ease,
    transform 1s cubic-bezier(0.175, 0.885, 0.32, 1.275);
}

.WWDFooterAnimate.active {
  transform: translateY(0);
  opacity: 1;
  animation: bounce 1s cubic-bezier(0.175, 0.885, 0.32, 1.275) forwards;
}

form .reCaptcha1 {
  display: block;
}

form .reCaptcha2 {
  display: none;
  padding-top: 3%;
}

form .privacy-mob p {
  display: none;
}

@media screen and (max-width: 1450px) {
  form .input_fields .button_section .privacy {
    width: 335px;
  }

  form .input_fields .button_section .privacy p {
    font-size: 16px;
  }
}

@media screen and (max-width: 1350px) {
  form .input_fields .button_section .submit_button .btn_submit {
    width: 171px;
    font-size: 17px;
  }

  .footer .wrapper .footer_container .footer_header span {
    font-size: 51px;
  }
}

@media screen and (max-width: 1024px) {
  form .input_fields .button_section .privacy {
    width: 225px;
  }

  form .input_fields .button_section .privacy p {
    font-size: 11px;
  }

  form .input_fields .button_section .privacy p span {
    font-size: 12px;
  }

  form .input_fields .button_section .submit_button .btn_submit {
    width: 153px;
    font-size: 14px;
  }

  .footer .wrapper .footer_container .footer_header span {
    font-size: 40px;
  }
}

@media screen and (max-width: 768px) {
  form .input_fields .button_section .submit_button .btn_submit {
    display: none;
  }

  form .textarea_field .submit_button .btn_submit {
    display: block;
  }

  form .textarea_field .submit_button {
    padding-top: 25px;
  }

  .footer_container .form_section form {
    flex-direction: column;
  }

  form .input_fields .button_section .privacy {
    width: 100%;
    padding-bottom: 25px;
    display: none;
  }

  form .input_fields .button_section .privacy p {
    font-size: 18px;
  }
  form .input_fields .button_section .privacy p span {
    font-size: 18px;
  }

  form .privacy-mob p {
    display: block;
    font-size: 16px;
    font-family: "Poppins";
    font-weight: 500;
    line-height: 40px;
    letter-spacing: -0.48px;
    color: rgba(171, 189, 255, 0.5);
    padding-bottom: 5%;
  }

  .footer .wrapper .footer_container .footer_header span {
    font-size: 32px;
    line-height: 42px;
  }
  .footer_container
    .form_section
    form
    .input_fields
    .input_container:nth-child(2) {
    padding-top: 8%;
  }
  .footer_container .form_section form .textarea_field textarea {
    height: 200px;
  }

  form .reCaptcha1 {
    display: none;
  }

  form .reCaptcha2 {
    display: block;
  }
}

@media only screen and (max-width: 480px) {
  form .input_fields .button_section .privacy p,
  form .input_fields .button_section .privacy p span {
    font-size: 16px;
  }
  form .textarea_field .submit_button .btn_submit {
    width: 175px;
    height: 50px;
    font-size: 18px;
  }
}
@media only screen and (max-width: 380px) {
  .footer .wrapper .footer_container .footer_header span {
    font-size: 20px;
  }
  form .input_fields .button_section .privacy p,
  form .input_fields .button_section .privacy p span {
    font-size: 12px;
  }
}
