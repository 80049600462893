.techstack {
  color: #ffffff;
}

.techstack-heading {
  padding: 70px 0;
  position: relative;
  overflow: hidden;
  animation: colorRotate 8s forwards infinite;
}

.techstack-heading .animation-part {
  width: 50%;
  height: 100%;
  z-index: 1;
  position: absolute;
  right: 0;
  top: 0;
}

.animation-part ul {
  padding-left: 5%;
  display: flex;
  flex-wrap: wrap;
}
.dropdown-button {
  display: block;
  width: 100%;
}

.dropdown-button ul li {
  margin-bottom: 12px;
}

.drop-data {
  width: 100%;
  display: flex;
  justify-content: center;
  border-top: 1px solid #0c1b41;
}
.drop-image {
  width: 40px;
  height: 40px;
}

.animation-part ul li {
  width: 60px;
  height: 60px;
  border-radius: 10px;
  background-color: transparent;
  transition: 0.2s ease-in;
}

.animation-part ul li:hover {
  background-color: #080d27;
}

.techstack-heading .wrapper {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}

.techstack-heading .wrapper > h3,
.techstack-heading .wrapper > p {
  margin-left: 3.8%;
  position: relative;
  z-index: 10;
}

.techstack-heading .wrapper > h3 {
  margin-bottom: 30px;
  color: #06a9ca;
  font-size: 48px;
  text-transform: uppercase;
  font-weight: 800;
}

.techstack-heading .wrapper > p {
  color: #c4cbf5;
  font-size: 26px;
  font-weight: 500;
}

.techstack-details {
  overflow: hidden;
  position: relative;
  background: #0b1235;
  padding: 4% 0 2%;
  /* width: 112%; */
}

.techstack-details .animation-part {
  width: 51.8%;
  height: 30%;
  position: absolute;
  right: 0;
  bottom: 0;
  background: url("../../Assets/images/VectorTeckStack1.png") no-repeat center
    center/120% 100%;
  animation: colorRotate1 5s forwards infinite;
}

.techstack-details .wrapper {
  width: 82%;
  display: flex;
  justify-content: center;
  align-items: flex-start;
}

.techstack-details .wrapper > ul {
  /* padding: 50px 0; */
  flex-basis: 48%;
  display: flex;
  flex-direction: column;
}

.techstack-details .wrapper > ul:first-child {
  border-right: 2px solid #0c1b41;
  margin-right: 3.9%;
  margin-bottom: 12px;
}

.techstack-details .wrapper > ul > li:not(:last-child) {
  margin-bottom: 12px;
}

.tech_stack_content {
  width: 85%;
  padding: 1% 2%;
  border-radius: 20px;
  border-top-left-radius: 75px;
  transition: 0.3s ease-in-out;
}

.tech_stack_content > div,
.tech_stack_content > div > div {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.tech_stack_content > div > div {
  flex-basis: 65%;
}

.tech_stack_content > div > div > span:first-child {
  flex-basis: 10%;
  padding: 7%;
  border: 4px solid #162d6c;
  border-radius: 50%;
  background: #0d1a46;
}

.tech_stack_content > div > div > span:first-child > img {
  width: 100%;
  height: 100%;
}

.tech_stack_content > div > div > span:last-child {
  flex-basis: 65%;
  color: #f2f5f7;
  font-size: 25px;
  font-weight: 500;
  text-transform: capitalize;
}

.tech_stack_content > div > span {
  border-radius: 50%;
  margin-right: 3%;
  flex-basis: 1%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #06a9ca;
  position: relative;
  cursor: pointer;
  box-shadow: 0px 0px 16px 5px #00000040, 0px 4px 4px 0px #00000040;
}

.tech_stack_content > div > span::before {
  content: "";
  width: 100%;
  height: 100%;
  border-radius: 50%;
  position: absolute;
  top: 0;
  left: 0;
  background-color: transparent;
  box-shadow: inset 0px 3px 5px #1959ad;
}

.tech_stack_content > div > span > img {
  width: 40px;
  height: 40px;
  border: 0;
  border-radius: 50%;
}

.tech_content {
  max-height: 0;
  overflow: hidden;
}

.tech_content.show {
  height: auto;
  max-height: 9999px;
  padding: 5% 8% 3% 4%;
  color: #c4cbf5;
  font-weight: 400;
  line-height: 40px;
}

@keyframes colorRotate {
  0% {
    background: url(../../Assets/images/TechstackAnimation1.png);
    background-repeat: no-repeat;
    background-position: top;
    background-size: cover;
  }
  45% {
    background: url(../../Assets/images/TechstackAnimation2.png);
    background-repeat: no-repeat;
    background-position: top;
    background-size: cover;
  }
  70% {
    background: url(../../Assets/images/TechstackAnimation3.png);
    background-repeat: no-repeat;
    background-position: top;
    background-size: cover;
  }
  100% {
    background: url(../../Assets/images/TechstackAnimation1.png);
    background-repeat: no-repeat;
    background-position: top;
    background-size: cover;
  }
}

@keyframes colorRotate1 {
  0% {
    background-position: 100% 0;
  }
  50% {
    background-position: 0 150%;
  }
  100% {
    background-position: 100% 0;
  }
}

@media only screen and (max-width: 3500px) {
  .tech_stack_content > div > div > span:last-child {
    font-size: 30px;
  }
  .tech_content.show {
    font-size: 25px;
  }
}
@media only screen and (max-width: 2233px) {
  .tech_content.show {
    font-size: 23px;
  }
}
@media only screen and (max-width: 2056px) {
  .tech_content.show {
    font-size: 22px;
  }
}
@media only screen and (max-width: 1975px) {
  .tech_content.show {
    font-size: 21px;
  }
  .tech_stack_content {
    border-top-left-radius: 65px;
  }
}
@media only screen and (max-width: 1888px) {
  .tech_content.show {
    font-size: 20px;
  }
}
@media only screen and (max-width: 1815px) {
  .tech_content.show {
    font-size: 19px;
  }
}
@media only screen and (max-width: 1722px) {
  .tech_content.show {
    font-size: 18px;
  }
}
@media only screen and (max-width: 1620px) {
  .tech_content.show {
    font-size: 17px;
    line-height: 35px;
  }
  .tech_stack_content {
    border-top-left-radius: 55px;
  }
}
@media only screen and (max-width: 1520px) {
  .tech_content.show {
    font-size: 16px;
  }
}
@media only screen and (max-width: 1434px) {
  .tech_content.show {
    font-size: 15px;
  }
}
@media only screen and (max-width: 1340px) {
  .tech_content.show {
    font-size: 14px;
  }
  .animation-part ul li {
    width: 50px;
    height: 50px;
  }
  .tech_stack_content {
    border-top-left-radius: 50px;
  }
}
@media only screen and (max-width: 1254px) {
  .techstack-heading .wrapper > h3 {
    font-size: 45px;
  }
  .techstack-heading .wrapper > p {
    font-size: 24px;
  }
  .tech_stack_content > div > div > span:last-child {
    font-size: 25px;
  }
  .tech_content.show {
    font-size: 13px;
    line-height: 22px;
  }
}
@media only screen and (max-width: 1165px) {
  .tech_content.show {
    font-size: 12px;
  }
  .tech_stack_content {
    border-top-left-radius: 40px;
  }
}
@media only screen and (max-width: 1080px) {
  .techstack-heading .wrapper > h3 {
    font-size: 42px;
  }

  .techstack-heading .wrapper > p {
    font-size: 22px;
  }
  .tech_stack_content > div > div > span:last-child {
    font-size: 23px;
  }
  .tech_content.show {
    font-size: 11px;
  }
}
@media only screen and (max-width: 985px) {
  .techstack-heading .wrapper > p {
    font-size: 21px;
  }
  .tech_stack_content > div > div > span:last-child {
    font-size: 21px;
  }
  .tech_content.show {
    font-size: 10.2px;
  }
  .tech_stack_content > div > div > span:first-child {
    flex-basis: 10%;
  }
  .tech_stack_content {
    border-top-left-radius: 65px;
  }
}
@media only screen and (max-width: 940px) {
  .techstack-heading .wrapper > h3 {
    font-size: 40px;
  }
  .second-data {
    display: none !important;
    width: 100%;
  }
  .techstack-heading .wrapper > p {
    font-size: 20px;
  }
  .techstack-details .wrapper {
    flex-direction: column;
    padding: 30px 0;
  }
  .techstack-heading .animation-part,
  .techstack-details .animation-part {
    display: none;
  }
  .techstack-details .wrapper > ul {
    padding: 40px 0;
  }
  .techstack-details .wrapper > ul:first-child {
    margin-right: 0;
    border-right: 0;
    /* border-bottom: 1px solid #0C1B41 */
  }
  .tech_stack_content > div > div > span:first-child {
    padding: 5%;
    flex-basis: 7%;
  }
  .tech_stack_content > div > div > span:last-child {
    flex-basis: 70%;
  }
  .tech_stack_content > div > span > img {
    width: 35px;
    height: 35px;
  }
  .tech_content.show {
    font-size: 21px;
    line-height: 35px;
  }
  .animation-part ul li {
    width: 45px;
    height: 45px;
  }
}

@media only screen and (max-width: 902px) {
  .tech_content.show {
    font-size: 20px;
  }
}
@media only screen and (max-width: 864px) {
  .tech_content.show {
    font-size: 19px;
  }
}
@media only screen and (max-width: 816px) {
  .tech_content.show {
    font-size: 18px;
  }
}
@media only screen and (max-width: 776px) {
  .techstack-heading .wrapper > h3 {
    font-size: 35px;
  }
  .techstack-heading .wrapper > p {
    font-size: 20px;
  }
  .tech_content.show {
    font-size: 17px;
    line-height: 27px;
  }
  .animation-part ul li {
    width: 40px;
    height: 40px;
  }
}
@media only screen and (max-width: 734px) {
  .tech_content.show {
    font-size: 16px;
  }
  .tech_stack_content {
    border-top-left-radius: 50px;
  }
}
@media only screen and (max-width: 688px) {
  .tech_content.show {
    font-size: 15px;
  }
}
@media only screen and (max-width: 640px) {
  .tech_content.show {
    font-size: 14px;
  }
}
@media only screen and (max-width: 600px) {
  .tech_stack_content > div > div > span:last-child {
    flex-basis: 60%;
  }
  .tech_stack_content > div > span > img {
    width: 30px;
    height: 30px;
  }
  .tech_stack_content > div > div > span:first-child {
    padding: 6%;
    margin-left: 0%;
    flex-basis: 10%;
  }
  .tech_content.show {
    font-size: 13px;
  }
  .animation-part ul li {
    width: 30px;
    height: 30px;
  }
}
@media only screen and (max-width: 557px) {
  .techstack-details .wrapper {
    width: 79%;
 }
  .techstack-heading .wrapper > h3 {
    font-size: 30px;
  }
  .techstack-heading .wrapper > p {
    font-size: 16px;
  }
  .tech_content.show {
    font-size: 12.5px;
    line-height: 23px;
  }
  .tech_stack_content {
    width: 100%;
    border-top-left-radius: 40px;
  }
}
@media only screen and (max-width: 536px) {
  .tech_stack_content > div > div > span:last-child {
    font-size: 20px;
  }
  .tech_content.show {
    font-size: 12px;
    line-height: 20px;
  }
}
@media only screen and (max-width: 516px) {
  .tech_content.show {
    font-size: 11.5px;
  }
}
@media only screen and (max-width: 495px) {
  .tech_stack_content > div > div > span:last-child {
    font-size: 16px;
  }
  .tech_content.show {
    font-size: 11px;
  }
}
@media only screen and (max-width: 472px) {
  .tech_content.show {
    font-size: 10.5px;
  }
  .animation-part {
    display: none;
  }
}
@media only screen and (max-width: 455px) {
  .techstack-heading .wrapper > h3 {
    font-size: 30px;
  }
  .techstack-heading .wrapper > p {
    font-size: 27px;
  }
  .tech_stack_content > div > div > span:first-child {
    flex-basis: 15%;
  }
  .tech_content.show {
    font-size: 10px;
    line-height: 18px;
  }
  .tech_stack_content {
    border-top-left-radius: 40px;
  }
}
@media only screen and (max-width: 428px) {
  .tech_content.show {
    font-size: 9.4px;
  }
}
@media only screen and (max-width: 402px) {
  .tech_content.show {
    padding: 5% 4% 3%;
  }
}
@media only screen and (max-width: 382px) {
  .techstack-heading .wrapper > h3 {
    font-size: 23px;
  }
  .techstack-heading .wrapper > p {
    font-size: 11px;
  }
  .tech_stack_content > div > div > span:last-child {
    font-size: 13px;
  }
  .tech_content.show {
    font-size: 8.9px;
  }
  .tech_stack_content > div > div > span:last-child {
    flex-basis: 58%;
  }
  .tech_stack_content > div > div > span:first-child {
    padding: 7%;
    flex-basis: 12%;
  }
  .tech_stack_content > div > span > img {
    width: 25px;
    height: 25px;
  }
}
@media only screen and (max-width: 364px) {
  .tech_content.show {
    font-size: 8.3px;
  }
}
@media only screen and (max-width: 340px) {
  .techstack-heading .wrapper > h3 {
    font-size: 21px;
  }
  .techstack-heading .wrapper > p {
    font-size: 10px;
  }
  .tech_stack_content > div > div > span:last-child {
    font-size: 12px;
  }
  .tech_content.show {
    font-size: 8px;
  }
}

@media only screen and (max-width: 323px) {
  .tech_stack_content > div > div > span:first-child {
    padding: 8%;
    flex-basis: 15%;
  }
  .tech_stack_content > div > div > span:last-child {
    flex-basis: 52%;
  }
  .tech_content.show {
    font-size: 7, 6px;
  }
}
