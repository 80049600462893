.body-no-scroll {
  overflow: hidden;
}

.Timeline {
  width: 100%;
  height: 100%;
  background-color: #0c1838;
  padding: 3% 0 0 0;
}

.Timeline-bg h1 {
  padding: 21px 75px 0 75px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.Timeline-bg p {
  width: 78%;
  margin: 0 auto;
  color: #fff;
  font-size: 26px;
  line-height: 45px;
}

/* New code */
.imgctn {
  width: 77%;
  /* height: 400px; */
  margin: 0 auto;
  padding: 16.5% 0;
  overflow: hidden;
  position: relative;
}

.rotate {
  clip-path: polygon(0 0, 100% 0, 80% 100%, 20% 100%);
  border-radius: 12px;
  filter: brightness(0.2);
  transition: 0.3s ease;
}

.negative {
  clip-path: polygon(20% 0%, 80% 0%, 100% 100%, 0% 100%);
  border-radius: 12px;
  filter: brightness(0.2);
  transition: 0.3s ease;
}

.imgctn img {
  padding: 10px 0;
  position: absolute;
  top: 50px;
  /* transform: translateY(120%); */

  transition: 0.8s ease;
  border-radius: 40px;
}

.imgctn img:nth-child(1){
  /* object-position: center calc(100% - 220px); */
  aspect-ratio: 3/1;
  object-fit: cover;
}

.imgctn img:nth-child(2){
  /* object-position: center calc(100% - 130px); */
  aspect-ratio: 3/1;
  object-fit: cover;
}

.imgctn img:nth-child(3){
  /* object-position: center calc(100% - 350px); */
  aspect-ratio: 3/1;
  object-fit: cover;
}

.imgctn img:nth-child(4){
  /* object-position: center calc(100% - 340px); */
  aspect-ratio: 3/1;
  object-fit: cover;
}

.imgctn img:nth-child(5){
  object-position: center calc(100% - -150px);
  aspect-ratio: 3/1;
  object-fit: cover;
}

.initial {
  clip-path: polygon(0 0, 100% 0, 100% 100%, 0% 100%);
}

.imgctn {
  -ms-overflow-style: none; /* Internet Explorer 10+ */
  scrollbar-width: none; /* Firefox */
}
.imgctn::-webkit-scrollbar {
  display: none; /* Safari and Chrome */
}

.btnctn li span {
  color: #fff;
  font-size: 46px;
  font-weight: 600;
  line-height: 69px;
}
.dots__dot {
  border: none;
  background-color: transparent;
  opacity: 1;
  height: 32px;
  width: 32px;
  border: 3px solid #06a9ca;
  border-radius: 50%;
  /* margin-right: 1.75rem; */
  cursor: pointer;
  transition: all 0.5s;

  /* Only necessary when overlying images */
  /* box-shadow: 0 0.6rem 1.5rem rgba(0, 0, 0, 0.7); */
}

.progress-line {
  height: 2px; /* Adjust the height as needed */
  background-color: #06a9ca; /* Set the color of the progress bar */
  position: absolute;
  top: 19.5%;
  left: 10.8%;
  transition: width 0.3s ease; /* Add transition for smooth width change */
  /* z-index: 1; */
}

.btnctn {
  position: relative; /* Ensure the progress bar aligns properly */
  width: 86%; /* Set the total width of the button container */
  margin: 0 auto; /* Center the button container */
  padding: 20px 0 50px 0;
  display: flex; /* Use flexbox to align buttons and progress line */
}

.btnctn li {
  flex: 1; /* Make each button container occupy equal width */
  display: flex;
  flex-direction: column;
  /* justify-content: center; */
  align-items: center;
  position: relative; /* Ensure proper stacking of progress line */
}

.dots__dot.active {
  background-color: rgb(6, 169, 202);
  box-shadow: 0px 0px 0px 2px #2ef2ff, 0px 0px 25px 0px #2ef2ff;
  /* box-shadow: 0px 0px 25px 0px #2EF2FF; */
  border: 5px solid #000;
  position: relative;
  z-index: 999;
}

.previous {
  background: #080d27;
  opacity: 1;
  position: relative;
  z-index: 999;
}

@media screen and (max-width: 2560px) {
  .progress-line {
    left: 10.5%;
  }
}

@media screen and (max-width: 1800px) {
  .imgctn {
    padding: 16.5% 0;
  }
  .progress-line {
    left: 10.5%;
  }
  .imgctn img {
    top: 40px;
  }
}

@media screen and (max-width: 1550px) {
  .imgctn {
    padding: 16% 0;
    width: 75%;
  }
  .progress-line {
    left: 10.5%;
  }
  .imgctn img {
    top: 40px;
  }
  .btnctn {
    padding: 10px 0 20px 0;
  }
  .btnctn li span {
    font-size: 28px;
  }
  .Timeline-bg p {
    width: 75%;
    font-size: 20px;
    line-height: 30px;
}
.imgctn img:nth-child(5){
  object-position: center calc(100% - -130px);
}
}


@media screen and (max-width: 1350px) {
  .Timeline p {
    font-size: 18px;
    line-height: 35px;
  }
  .progress-line {
    left: 10.5%;
    top: 22.5%;
  }
  .imgctn {
    padding: 16% 0;
  }
  .imgctn img {
    top: 30px;
  }
  .btnctn {
    padding: 20px 0 20px 0;
  }
  .btnctn li span {
    font-size: 30px;
  }
  .imgctn img:nth-child(5){
    object-position: center calc(100% - -110px);
  }
}

@media screen and (max-width: 1150px) {
  .imgctn {
    padding: 18% 0;
  }
  .Timeline-bg p {
    width: 75%;
    font-size: 16px;
    line-height: 40px;
}
.imgctn img:nth-child(5){
  object-position: center calc(100% - -90px);
}
}

@media screen and (max-width: 1000px) {
  .imgctn {
    padding: 20% 0;
  }
  .Timeline-bg p {
    width: 75%;
  }

.imgctn img:nth-child(1){
  /* object-position: center calc(100% - 220px); */
  aspect-ratio: 3/1.1;
  object-fit: cover;
}

.imgctn img:nth-child(2){
  /* object-position: center calc(100% - 130px); */
  aspect-ratio: 3/1.1;
  object-fit: cover;
}

.imgctn img:nth-child(3){
  /* object-position: center calc(100% - 350px); */
  aspect-ratio: 3/1.1;
  object-fit: cover;
}

.imgctn img:nth-child(4){
  /* object-position: center calc(100% - 340px); */
  aspect-ratio: 3/1.1;
  object-fit: cover;
}

.imgctn img:nth-child(5){
  object-position: center calc(100% - 0px);
  aspect-ratio: 3/1.1;
  object-fit: cover;
}
}

@media screen and (max-width: 800px) {
  .Timeline p {
    font-size: 16px;
    line-height: 32px;
    width: 75%;
  }
  .imgctn {
    padding: 19% 0;
  }
  .imgctn img {
    border-radius: 25px;
  }
  .progress-line {
    left: 11%;
  }
  .btnctn li span {
    font-size: 25px;
  }
}

@media screen and (max-width: 600px) {
  .Timeline p {
    font-size: 16px;
    line-height: 32px;
  }
  .imgctn {
    padding: 25% 0;
  }
  .progress-line {
    left: 11%;
  }
  .btnctn li span {
    font-size: 20px;
  }
}

@media screen and (max-width: 800px) {
  .Timeline p {
    font-size: 16px;
    line-height: 32px;
  }
  .imgctn {
    padding: 23% 0;
  }
  .imgctn img {
    top: 50px;
  }
  .progress-line {
    left: 11%;
  }
  .btnctn li span {
    font-size: 20px;
  }
  .imgctn img:nth-child(1){
    /* object-position: center calc(100% - 220px); */
    aspect-ratio: 3/1.3;
    object-fit: cover;
  }
  
  .imgctn img:nth-child(2){
    /* object-position: center calc(100% - 130px); */
    aspect-ratio: 3/1.3;
    object-fit: cover;
  }
  
  .imgctn img:nth-child(3){
    /* object-position: center calc(100% - 350px); */
    aspect-ratio: 3/1.3;
    object-fit: cover;
  }
  
  .imgctn img:nth-child(4){
    /* object-position: center calc(100% - 340px); */
    aspect-ratio: 3/1.3;
    object-fit: cover;
  }
  
  .imgctn img:nth-child(5){
    object-position: center calc(100% - 0px);
    aspect-ratio: 3/1.3;
    object-fit: cover;
  }
}

@media screen and (max-width: 720px) {
  .Timeline p {
    font-size: 16px;
    line-height: 32px;
  }
  .imgctn {
    padding: 30% 0;
  }
  .imgctn img {
    top: 40px;
  }
  .progress-line {
    left: 11%;
  }
  .btnctn li span {
    font-size: 16px;
  }
  .dots__dot {
    height: 25px;
    width: 25px;
  }
  .imgctn img:nth-child(1){
    /* object-position: center calc(100% - 220px); */
    aspect-ratio: 3/1.6;
    object-fit: cover;
  }
  
  .imgctn img:nth-child(2){
    /* object-position: center calc(100% - 130px); */
    aspect-ratio: 3/1.6;
    object-fit: cover;
  }
  
  .imgctn img:nth-child(3){
    /* object-position: center calc(100% - 350px); */
    aspect-ratio: 3/1.6;
    object-fit: cover;
  }
  
  .imgctn img:nth-child(4){
    /* object-position: center calc(100% - 340px); */
    aspect-ratio: 3/1.6;
    object-fit: cover;
  }
  
  .imgctn img:nth-child(5){
    object-position: center calc(100% - 0px);
    aspect-ratio: 3/1.6;
    object-fit: cover;
  }
}

@media screen and (max-width: 450px) {
  .Timeline p {
    font-size: 14px;
    line-height: 25px;
  }
  .imgctn {
    padding: 37% 0;
  }
  .imgctn img {
    top: 40px;
  }
  .progress-line {
    left: 11%;
  }
  .btnctn li span {
    font-size: 16px;
  }
  .dots__dot {
    height: 28px;
    width: 25px;
  }
  .dots__dot.active {
    height: 31px;
  }
  .imgctn img:nth-child(1){
    /* object-position: center calc(100% - 220px); */
    aspect-ratio: 3/2;
    object-fit: cover;
  }
  
  .imgctn img:nth-child(2){
    /* object-position: center calc(100% - 130px); */
    aspect-ratio: 3/2;
    object-fit: cover;
  }
  
  .imgctn img:nth-child(3){
    /* object-position: center calc(100% - 350px); */
    aspect-ratio: 3/2;
    object-fit: cover;
  }
  
  .imgctn img:nth-child(4){
    /* object-position: center calc(100% - 340px); */
    aspect-ratio: 3/2;
    object-fit: cover;
  }
  
  .imgctn img:nth-child(5){
    object-position: center calc(100% - 0px);
    aspect-ratio: 3/2;
    object-fit: cover;
  }
}

@media screen and (max-width: 400px) {
  .Timeline p {
    font-size: 14px;
    line-height: 25px;
  }
  .imgctn {
    padding: 40% 0;
  }
}
