/*
* Prefixed by https://autoprefixer.github.io
* PostCSS: v8.4.14,
* Autoprefixer: v10.4.7
* Browsers: last 4 version
*/

.ourApproach-CaseStudyOuterCtn {
  padding: 60px 0;
  -webkit-animation: backgroundImg 3s infinite forwards;
          animation: backgroundImg 3s infinite forwards;
  
}

.mob-heading {display: none;}
.ourApproach-CaseStudyOuterCtn .wrapper {
  width: 89%;
}
.ourApproach-CaseStudyInnerCtn {
  background: -webkit-gradient(linear, left top, left bottom, color-stop(50%, #151f4a), to(rgba(0, 0, 0, 0))),
  -webkit-gradient(linear, left bottom, left top, from(#0e1434), to(#2a3b64));
  background: -o-linear-gradient(top, #151f4a 50%, rgba(0, 0, 0, 0) 100%),
  -o-linear-gradient(bottom, #0e1434, #2a3b64);
  background: linear-gradient(180deg, #151f4a 50%, rgba(0, 0, 0, 0) 100%),
  linear-gradient(0deg, #0e1434, #2a3b64);
  border-radius: 40px;
  border: 2px solid #334679;
  overflow: hidden;
}
.ourApproach-CaseStudyInnerCtn .wrapper {
  width: 91.5%;
}
.caseStudiesCtn {
  position: relative;
}

.caseStudiesCtn>h1 {
  font-size: 2.5vw;
  font-weight: 800;
  text-transform: uppercase;
  font-family: "Poppins";
  color: #06a9ca;
  padding: 18px 0 0;
  z-index: 2;
  position: absolute;
  /* padding-right: 3.5%; */
  text-align: end;
  /* width: fit-content; */
  right: 5%;
  top: 1%;
}

.caseStudiesMain {
  padding: 7% 0 3% 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  
}

.caseStudiesBg {
  width: 52%;
  height: -webkit-fill-available;
  position: absolute;
  right: -4.8%;
  top: -4%;
  background: -webkit-gradient(linear, left top, left bottom, color-stop(4.25%, rgba(50, 67, 135, 0.53)), to(#080D27));
  background: -o-linear-gradient(top, rgba(50, 67, 135, 0.53) 4.25%, #080D27 100%);
  background: linear-gradient(180deg, rgba(50, 67, 135, 0.53) 4.25%, #080D27 100%);;
  border-radius: 0 0 40px 0;
}

.caseStudiesImgCtn, .caseStidiesDescription{
  -ms-overflow-style: none;  /* Internet Explorer 10+ */
  scrollbar-width: none;  /* Firefox */
  overflow-x: hidden;
}
.caseStudiesImgCtn::-webkit-scrollbar, .caseStidiesDescription::-webkit-scrollbar{ 
  display: none;  /* Safari and Chrome */
}

.caseStudiesImgCtn {
  -ms-flex-preferred-size: 48%;
      flex-basis: 48%;
  height: 643px;
  border-radius: 14px;
  overflow-y: scroll;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  /* border: 0.5px solid #080D27; */
  .radius-all { border-radius: 10px; -moz-border-radius: 10px; -webkit-border-radius: 10px; }

}

.caseStudiesImgCtn img{
  width: 100%;
  height: 700px;
  -o-object-fit: contain;
     object-fit: contain;
  position: sticky;
  top: 0rem;
  left: 1px;
  overflow: hidden;
  background-color: #f7fbfe;
}

.caseStudiesImgCtn img:last-child{
  height: 643px;
  background-color: #f7fbfe;
  /* padding-bottom: 10px; */
}

.caseStidiesDescription {
  -ms-flex-preferred-size: 43%;
      flex-basis: 43%;
  height: 644px;
  padding-left: 2%;
  overflow-y: scroll;
  position: relative;
  z-index: 2;
}

.caseStudyContainerBlock {
  width: 88%;
  height: 657px;
  margin-top: 5%;
}
.caseStudyContainerBlock:first-child {
  margin-top: 0;
  /* padding-bottom: 15%; */
}

.caseStudyContainerBlock:nth-child(3) {
  margin-top: 8%;
  /* padding-bottom: 15%; */
}



.caseStudyContainerBlock h2 {
  color:  #F2F5F7;
font-family: Poppins;
font-size: 26px;
font-style: normal;
font-weight: 500;
line-height: normal;
}
.caseStudyContainerBlock:last-child h2 {
  padding-top: 2%;
}

.caseStudyContainerBlock p {
  margin: 14% 0;
  color: var(--TEXT-SECONDARY, #C4CBF5);
font-family: Poppins;
font-size: 20px;
font-style: normal;
font-weight: 400;
line-height: normal;
}

.caseStudyContainerBlock button {
  height: 83px;
  width: 280px;
  border: none;
  border-radius: 16px;
  color: #06a9ca;
  background: -webkit-gradient(linear, left top, left bottom, from(#1f3265), to(#091649));
  background: -o-linear-gradient(top, #1f3265 0%, #091649 100%);
  background: linear-gradient(180deg, #1f3265 0%, #091649 100%);
  font-size: 30px;
  font-weight: 700;
  -webkit-box-shadow: 0px -14px 48px 0px #28336fb2;
          box-shadow: 0px -14px 48px 0px #28336fb2;
  -webkit-box-shadow: 0px 16px 24px 0px #00000040;
          box-shadow: 0px 16px 24px 0px #00000040;
  margin-right: 80%;
  -webkit-transition: -webkit-transform 0.2s;
  transition: -webkit-transform 0.2s;
  -o-transition: transform 0.2s;
  transition: transform 0.2s;
  transition: transform 0.2s, -webkit-transform 0.2s;
  /* box-shadow: 0px -14px 48px 0px #353535b2; */

  
}
@media only screen and (min-width : 580px) {
.caseStudyContainerBlock button:hover {
  -ms-transform: scale(1.05);
  -webkit-transform: scale(1.05);
  transform: scale(1.05);
  -webkit-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
  font-size: 30.02px;
  box-shadow: 0px 0px 50px 5px #a0a0a040;
  /* background: -webkit-gradient(linear, left top, left bottom, from(#334679), to(#162561)); */
  /* background: -o-linear-gradient(top, #334679 0%, #162561 100%); */
  /* background: linear-gradient(180deg, #334679 0%, #162561 100%); */
}
}
@-webkit-keyframes backgroundImg {
  0% {
    background: url(../../Assets/images/casestudiesbg.png);
    background-repeat: no-repeat;
    background-position: top;
    background-size: cover;
    
    /* object-fit: contain; */
  }
  50% {
    background: url(../../Assets/images/Frame\ 1.png);
    background-repeat: no-repeat;
    background-position: top;
    background-size: cover;
    /* object-fit: contain; */
  }
  100% {
    background: url(../../Assets/images/casestudiesbg.png);
    background-repeat: no-repeat;
    background-position: top;
    background-size: cover;
    /* object-fit: contain; */
  }
}
@keyframes backgroundImg {
  0% {
    background: url(../../Assets/images/casestudiesbg.png);
    background-repeat: no-repeat;
    background-position: top;
    background-size: cover;
    
    /* object-fit: contain; */
  }
  50% {
    background: url(../../Assets/images/Frame\ 1.png);
    background-repeat: no-repeat;
    background-position: top;
    background-size: cover;
    /* object-fit: contain; */
  }
  100% {
    background: url(../../Assets/images/casestudiesbg.png);
    background-repeat: no-repeat;
    background-position: top;
    background-size: cover;
    /* object-fit: contain; */
  }
}

@media only screen and (min-width : 2560px) {
 wrapper { 
  max-width: 2560px;
  width: 89%;
  margin: 0 auto;
}

.caseStudyContainerBlock:nth-child(3) {
  margin-top: 5%;
  /* padding-bottom: 15%; */
}


.caseStudiesBg {
  width: 54%;
  right: -6.2%;

 
}


}

@media only screen and (max-width : 1550px) {
  .caseStudiesBg {
    width: 54%;
    right: -6.2%;
}

.caseStudiesImgCtn, .caseStudiesImgCtn img{

  height: 500px;

}
.caseStidiesDescription {

  height: 500px;

}
.caseStudiesImgCtn img:last-child {
  height: 500px;
  /* padding-bottom: 10px; */
}
.caseStudyContainerBlock h2 {

  font-size: 19px;

}
.caseStudyContainerBlock p {

  font-size: 15px;

}

.caseStudyContainerBlock {
  height: 501px;
  margin-top: 0;
}
.caseStudyContainerBlock:nth-child(3) {
  margin-top: 0;
  /* padding-bottom: 15%; */
}

.caseStudyContainerBlock button {
  height: 62px;
  width: 226px;
  font-size: 23px;

}

}

@media only screen and (max-width : 1024px) {
  .ourApproach-CaseStudyOuterCtn {
    padding: 60px 50px;
  }

  .caseStudiesImgCtn, .caseStudiesImgCtn img {
    height: 400px;
}
.caseStidiesDescription {
  height: 397px;
}
.caseStudyContainerBlock h2 {
  font-size: 14px;
}
.caseStudyContainerBlock p {
  font-size: 11px;
}
.caseStudyContainerBlock {
  height: 400px;
}
.caseStudiesImgCtn img:last-child {
  height: 400px;
  /* padding-bottom: 10px; */
}

.caseStudiesCtn>h1 {

  padding: 15px 0 0;
  right: 0.5%;
}
}
@media only screen and (max-width: 780px) {
  .ourApproach-CaseStudyOuterCtn .wrapper {
    width: 100%;
    border-bottom-left-radius: 40px;
    border-bottom-right-radius: 40px;
  }

  .ourApproach-CaseStudyOuterCtn {
    padding: 60px 0 20px 0;
   
  }
  .ourApproach-CaseStudyInnerCtn {
    border: none;
    border-radius: 0;
    background: transparent;
  }
  .ourApproach-CaseStudyInnerCtn .wrapper {
    width: 100%;
    
}

.caseStudyContainerBlock {
  height: 400px;
}

  .caseStudiesMain {
    padding: 0;
    display: inline-block;
    background: linear-gradient(180deg, rgba(50, 67, 135, 0.53) 4.25%, #080D27 100%),
    linear-gradient(0deg, #334679, #334679);
    
  }

  .caseStudiesImgBg {
    background-color:#080D27;

  }
  /* .caseStudiesCtn > h1 {
    font-size: 32px;  
    text-align: start; 
    right: 14%;
 
} */

.caseStudyContainerBlock {
  padding-left: 6%;
}

.caseStudyContainerBlock p {
  margin: 10% 0;
}
.caseStudyContainerBlock h2 {
  padding-top: 7%;
}

.caseStudiesImgCtn{
  /* height: 293px; */
  margin: 5%;

}
.caseStudiesCtn > h1 {
  display: none;
}

.mob-heading {
  display: block;
  font-size: 32px;
  font-weight: 800;
  text-transform: uppercase;
  font-family: "Poppins";
  color: #06a9ca;
  padding: 40px 0 0;
  z-index: 2;
  padding-left: 8%;
  /* background: linear-gradient(180deg, #334679 4.25%,#334679 100%),
  linear-gradient(0deg, #334679, #334679); */
}
.caseStudiesBg {
  background: none;
}

/* .caseStidiesDescription {
  background: linear-gradient(180deg, rgba(50, 67, 135, 0.53) 4.25%, #080D27 100%),
linear-gradient(0deg, #334679, #334679);

} */

.caseStudyContainerBlock h2 {
  font-size: 23px;
}
.caseStudyContainerBlock p {
  margin: 4% 0;
  font-size: 13px;
}
.caseStudiesImgBg {
    width: 110%;
    right: -5%;
    position: absolute;
    height: 470px;
    background-color: #080d27;
    border-bottom: 2px solid #334679;
    border-radius: 0px 0px 60px 60px;
}

  .caseStudiesCtn > h1 {
    text-align: start;
    font-size: 34px;
    line-height: 51px;
    right: -14px;
    margin-left: 6px;
    top: 310px;
  }

  .caseStudiesImgCtn img:last-child {
    /* padding-bottom: 10px; */
    height: 430px;
    object-fit: contain;
    object-position: center calc(100% - 2px);
    background-color: #f7fbfe;
  }
}


@media only screen and (max-width: 400px) {
  .mob-heading {
    font-size: 24px;
  }
  .caseStudyContainerBlock h2 {
    font-size: 18px;
  }
  .caseStudyContainerBlock p {
    font-size: 11px;
  }
  .caseStidiesDescription {
    height: 282px;
  }
  .caseStudyContainerBlock h2 {
    padding-top: 2%;
}

.caseStudiesImgCtn {
  height: 190px;

}
.caseStudiesImgBg {

  height: 230px;

}
.caseStudiesImgCtn img {
  object-fit: fill;
  object-position: center calc(100% - 130px);
}
.caseStudiesImgCtn img:first-child {
  height: 194px;
  /* padding-bottom: 10px; */
  object-position: center calc(100% - -6px);
}



.caseStudiesImgCtn img:last-child {
  /* height: 190px; */
  /* padding-bottom: 10px; */
  object-position: center calc(100% - -12px);
}
.caseStudyContainerBlock button {
  height: 40px;
  width: 145px;
  font-size: 16px;
}
.caseStudyContainerBlock:last-child {
  height: 279px;
}
}

@media only screen and (max-width: 300px) {
  .caseStudyContainerBlock {
    height: 300px;
}
  .caseStudyContainerBlock h2 {
    font-size: 13px;
}
.caseStudyContainerBlock p {
  font-size: 9px;
}
.caseStidiesDescription {
  height: 255px;
}
.mob-heading {
  font-size: 21px;
}
.caseStudyContainerBlock:last-child {
  height: 211px;
  padding-top: 35%;
}
.caseStudiesImgCtn img:last-child {
  height: 190px;
  /* padding-bottom: 10px; */
  object-position: center calc(100% - -0px);
}
}