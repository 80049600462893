.case_study_container {

  -webkit-animation: backgroundImg 3s infinite forwards;
  animation: backgroundImg 3s infinite forwards;
  padding-bottom: 5%;

}

.case_study_container .wrapper .main_head {

  display: flex;
  justify-content: center;
  align-items: center;
  padding: 60px 0 5px 0;

}

.case_study_container .wrapper .main_head span {

  font-family: 'Poppins';
  font-weight: 700;
  font-size: 60px;
  line-height: 90px;
  color: #06A9CA;
}

.case_study_container .wrapper .card_scroller {

  scroll-snap-type: y proximity;
  overflow-y: scroll;
  scroll-behavior: smooth;
  height: 580px;
}

.case_study_container .wrapper .card_scroller {
  scrollbar-width: none;
  -ms-overflow-style: none;
}

.case_study_container .wrapper .card_scroller::-webkit-scrollbar {
  display: none;
}


/* Card Styling Start From Here */

.CSContainer,
.CSContainer .CSSection  {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.CSContainer .CSSection {
  padding: 2%;
  border-radius: 2%;
  background: linear-gradient(180deg, #080D27 0%, #051F55 100%),
linear-gradient(0deg, #334679, #334679);

}



.CSContainer .CSSection .CSSectionLeft {
  flex-basis: 35%;
  padding: 5%;
}

.Card_Headline {
  font-size: 48px;
  color:#F0F8FF;
  font-family: Poppins;
  font-weight: 800;
}

.Card_Headline .ViewPDF_Button {
  display: none;
}

.Card_Para {
  font-family: poppins;
  font-size: 25px;
  line-height: 42px;
  font-weight: 400;
  color: #F0F8FF;;
}

.CSContainer .CSSection .CSSectionRight {
  flex-basis: 65%;
}

.Cards_Header {
  font-size: 60px;
  text-align: center;
  padding: 2%;
  color: #06A9CA;
  font-weight: bold;
  font-family: poppins;
}

.ViewPDF_Button {
  width: 260px;
  height: 70.03px;
  border: none;
  outline: 0%;
  border-radius: 16px;
  font-family: poppins;
  font-size: 30px;
  color: #06A9CA;
  margin-top: 5%;
  background: linear-gradient(180deg, #334679 0%, #162561 100%);
}

@media screen and (max-width: 1400px) {

  .Card_Headline {
    font-size: 40px;
    color:#F0F8FF;
    font-family: Poppins;
    font-weight: 800;
  }

  .Card_Para {
    font-family: poppins;
    font-size: 20px;
    line-height: 38px;
    font-weight: 400;
    color: #F0F8FF;;
  }

  .ViewPDF_Button {
    width: 200px;
    height: 60.03px;
    border: none;
    outline: 0%;
    border-radius: 16px;
    font-family: poppins;
    font-size: 28px;
    color: #06A9CA;
    margin-top: 2%;
    background: linear-gradient(180deg, #334679 0%, #162561 100%);
  }
}

@media screen and (max-width: 1250px) {

  .Card_Headline {
    font-size: 36px;
    color:#F0F8FF;
    font-family: Poppins;
    font-weight: 800;
  }

  .Card_Para {
    font-family: poppins;
    font-size: 17px;
    line-height: 32px;
    font-weight: 400;
    color: #F0F8FF;;
  }

  .ViewPDF_Button {
    width: 160px;
    height: 58.03px;
    border: none;
    outline: 0%;
    border-radius: 16px;
    font-family: poppins;
    font-size: 24px;
    color: #06A9CA;
    margin-top: 2%;
    background: linear-gradient(180deg, #334679 0%, #162561 100%);
  }
}

@media screen and (max-width: 1150px) {

  .Card_Headline {
    font-size: 32px;
    color:#F0F8FF;
    font-family: Poppins;
    font-weight: 800;
  }

  .Card_Para {
    font-family: poppins;
    font-size: 14px;
    line-height: 28px;
    font-weight: 400;
    color: #F0F8FF;;
  }

  .ViewPDF_Button {
    width: 160px;
    height: 58.03px;
    border: none;
    outline: 0%;
    border-radius: 16px;
    font-family: poppins;
    font-size: 20px;
    color: #06A9CA;
    margin-top: 2%;
    background: linear-gradient(180deg, #334679 0%, #162561 100%);
  }
}

@media screen and (max-width: 1025px) {

  .CSContainer .CSSection .CSSectionLeft img {
    margin-left: 12%;
    width: 75%;
  }
  
  .Card_Headline .ViewPDF_Button {
    display: block;
    width: 120px;
    height: 40.03px;
    border: none;
    outline: 0;
    border-radius: 8px;
    font-family: poppins;
    font-size: 16px;
    color: #06A9CA;
    background: linear-gradient(180deg, #334679 0%, #162561 100%);
  }

  .CSContainer .CSSection {
    display: flex;
    flex-direction: column;
    padding: 2%;
  }

  .Cards_Header {
    font-size: 40px;
    text-align: center;
    padding: 2%;
    color: #06A9CA;
    font-weight: bold;
    font-family: poppins;
  }

  .Card_Headline {
    display: flex;
    align-items: center;
    justify-content: space-around;
    font-size: 24px;
    color:#F0F8FF;
    font-family: Poppins;
    font-weight: 800;
    margin-left: 2%;
  }

  .Card_Para {
    font-family: poppins;
    font-size: 20px;
    line-height: 25px;
    font-weight: 400;
    color: #F0F8FF;
    padding: 5% 10% 0% 15%;
    letter-spacing: 1px;
  }

  .ViewPDF_Button {
    display: none;
  }
 
}

@media screen and (max-width: 768px) {

  .Card_Headline .ViewPDF_Button {
    display: block;
    width: 120px;
    height: 40.03px;
    border: none;
    outline: 0;
    border-radius: 8px;
    font-family: poppins;
    font-size: 16px;
    color: #06A9CA;
    background: linear-gradient(180deg, #334679 0%, #162561 100%);
  }

  .CSContainer .CSSection {
    display: flex;
    flex-direction: column;
  }

  .Cards_Header {
    font-size: 40px;
    text-align: center;
    padding: 2%;
    color: #06A9CA;
    font-weight: bold;
    font-family: poppins;
  }

  .Card_Headline {
    display: flex;
    align-items: center;
    justify-content: space-around;
    font-size: 14px;
    color:#F0F8FF;
    font-family: Poppins;
    font-weight: 800;
  }

  .Card_Para {
    font-family: poppins;
    font-size: 12px;
    line-height: 20px;
    font-weight: 400;
    color: #F0F8FF;
  }

  .ViewPDF_Button {
    display: none;
  }
}

@media screen and (max-width: 450px) {

  .Card_Headline .ViewPDF_Button {
    display: block;
    width: 100px;
    height: 40.03px;
    border: none;
    outline: 0;
    border-radius: 8px;
    font-family: poppins;
    font-size: 12px;
    color: #06A9CA;
    background: linear-gradient(180deg, #334679 0%, #162561 100%);
  }

  .CSContainer .CSSection {
    display: flex;
    flex-direction: column;
  }

  .Cards_Header {
    font-size: 40px;
    text-align: center;
    padding: 2%;
    color: #06A9CA;
    font-weight: bold;
    font-family: poppins;
  }

  .Card_Headline {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 12px;
    color:#F0F8FF;
    font-family: Poppins;
    padding-left:12%;
    padding-right:12%;
    font-weight: 800;
  }

  .Card_Para {
    font-family: poppins;
    font-size: 12px;
    line-height: 20px;
    font-weight: 400;
    color: #F0F8FF;
  }

  .ViewPDF_Button {
    display: none;
  }
}

@media screen and (max-width: 370px) {

  .Card_Headline .ViewPDF_Button {
    display: block;
    width: 80px;
    height: 35.03px;
    border: none;
    outline: 0;
    border-radius: 8px;
    font-family: poppins;
    font-size: 10px;
    color: #06A9CA;
    background: linear-gradient(180deg, #334679 0%, #162561 100%);
  }

  .CSContainer .CSSection {
    display: flex;
    flex-direction: column;
  }

  .Cards_Header {
    font-size: 30px;
    text-align: center;
    padding: 2%;
    color: #06A9CA;
    font-weight: bold;
    font-family: poppins;
  }

  .Card_Headline {
    display: flex;
    align-items: center;
    justify-content: space-around;
    font-size: 8px;
    color:#F0F8FF;
    font-family: Poppins;
    font-weight: 800;
  }

  .Card_Para {
    font-family: poppins;
    font-size: 10px;
    line-height: 20px;
    font-weight: 400;
    color: #F0F8FF;
  }

  .ViewPDF_Button {
    display: none;
  }
}



/* Card Styling End From Here */

@-webkit-keyframes backgroundImg {
  0% {
    background: url(../../Assets/images/casestudiesbg.png);
    background-repeat: no-repeat;
    background-position: top;
    background-size: cover;

    /* object-fit: contain; */
  }

  50% {
    background: url(../../Assets/images/Frame\ 1.png);
    background-repeat: no-repeat;
    background-position: top;
    background-size: cover;
    /* object-fit: contain; */
  }

  100% {
    background: url(../../Assets/images/casestudiesbg.png);
    background-repeat: no-repeat;
    background-position: top;
    background-size: cover;
    /* object-fit: contain; */
  }
}

@keyframes backgroundImg {
  0% {
    background: url(../../Assets/images/casestudiesbg.png);
    background-repeat: no-repeat;
    background-position: top;
    background-size: cover;
    linear-gradient: (black, black)
      /* object-fit: contain; */
  }

  50% {
    background: url(../../Assets/images/Frame\ 1.png);
    background-repeat: no-repeat;
    background-position: top;
    background-size: cover;
    /* object-fit: contain; */
  }

  100% {
    background: url(../../Assets/images/casestudiesbg.png);
    background-repeat: no-repeat;
    background-position: top;
    background-size: cover;
    /* object-fit: contain; */
  }
}

@media screen and (max-width: 768px){

  .case_study_container .wrapper .card_scroller{

    display: flex;
    flex-direction: column;
    align-items: center;
    max-height: 897px !important;
    scroll-snap-type: y proximity;
    overflow-y: scroll;
    scroll-behavior: smooth;
  }

  .case_study_container .wrapper .main_head {

    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0px 0 0px 0;
  
  }

  .case_study_container .wrapper .main_head span{
    font-size: 34px;
  }

}

@media screen and (max-width: 320px){

  .case_study_container .wrapper .card_scroller{
    max-height: 950px !important;
  }

}