/* Import Fonts */

@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700;800;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300..800;1,300..800&display=swap');

/* ==========================================================================
   Reset Styles
   ========================================================================== */

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    font: inherit;
    vertical-align: baseline;
}

/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
    display: block;
}

/* Force scrollbar */
html {
    font-family: 'Open Sans', sans-serif;
    overflow-y: scroll;
}

/* Align radios and text inputs with their label */
input[type="radio"] {
    vertical-align: text-bottom;
}

/* Hand cursor on clickable input elements */
label,
input[type="button"],
input[type="submit"],
button {
    cursor: pointer;
}

strong {
    font-weight: bold;
    font-family: inherit;
    font-size: inherit;
}

em {
    font-style: italic;
}

img {
    width: 100%;
    border: 0;
    vertical-align: middle;
}

sub,
sup {
    vertical-align: baseline;
    position: relative;
    font-size: 55%;
    line-height: 0;
}

sup {
    top: -0.7em;
}

sub {
    bottom: -0.25em;
}

/* ==========================================================================
   Clear Fix
   ========================================================================== */

.cf:before,
.cf:after {
    display: table;
    content: " ";
}

.cf:after {
    clear: both;
}


/* ==========================================================================
   General Styles
   ========================================================================== */

/* Apply box-sizing to the entire document */
body {
    box-sizing: border-box;
    font-family: "Poppins", sans-serif;
    background-color: #eef0f1;
    overflow: hidden;
}

a {
    display: inline-block;
    text-decoration: none;
}

li {
    display: inline-block;
    list-style-type: none;
}

/* ==========================================================================
   Wrapper Styles
   ========================================================================== */

.wrapper {
    max-width: 1920px;
    width: 89%;
    margin: 0 auto;
}