.partner-card {
    margin-top: 60px;
    padding: 20px 0;
    flex-basis: 31%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    background-color: #ffffff;
    transition: .2s ease-in;
}
.partner-card figure {
    width: 90%;
    height: 209px;
    margin: 0 auto;
    display: flex;
    align-items: center;
}
.partner-card:not(:nth-of-type(3n)) {
    margin-right: 3.5%;
   
}

.partner-card:hover {
    transform: translateY(-20px);
}

.partner-card>li {
    width: 100%;
}

.partner-card li h3, 
.partner-card li h5, 
.partner-card li p {
    padding: 0 5%;
}

.partner-card li h3 {
    margin: 20px 0 50px;
    position: relative;
}

.partner-card li h3::before {
    content: "";
    width: 20%;
    height: 3px;
    position: absolute;
    left: 5%;
    bottom: -25%;
    background-color: #000000;
}

.partner-card li h5 {
    margin: 25px 0;
    color: #00b3cf;
    font-size: 20px;
    font-weight: 600;
    text-align: center;
}

@media only screen and (min-width:1854px) and (max-width:4000px) {
    .partner-card li h3 {
        font-size: 33px;
    }

    .partner-card li p {
        font-size: 20px;
    }
}

@media only screen and (min-width:1688px) and (max-width:1854px) {
    .partner-card li h3 {
        font-size: 30px;
    }
    .partner-card li p,
    .partner-card li h5 {
        font-size: 18px;
    }
}

@media only screen and (min-width:1527px) and (max-width:1688px) {
    .partner-card li h3 {
        font-size: 27px;
    }
    .partner-card li p,
    .partner-card li h5 {
        font-size: 16px;
    }
}

@media only screen and (min-width:1410px) and (max-width:1527px) {
    .partner-card li h3 {
        font-size: 25px;
    }
    .partner-card li p,
    .partner-card li h5 {
        font-size: 14px;
    }
}

@media only screen and (min-width:1299px) and (max-width:1410px) {
    .partner-card li h3 {
        font-size: 23px;
    }
    .partner-card li p,
    .partner-card li h5 {
        font-size: 14px;
    }
}

@media only screen and (min-width:1175px) and (max-width:1299px) {
    .partner-card li h3 {
        font-size: 21px;
    }
    .partner-card li p,
    .partner-card li h5 {
        font-size: 13px;
    }
}

@media only screen and (min-width:1073px) and (max-width:1175px) {
    .partner-card li h3 {
        font-size: 19px;
    }
    .partner-card li p,
    .partner-card li h5 {
        font-size: 12px;
    }
}
@media only screen and (min-width:961px) and (max-width:1073px) {
    .partner-card li h3 {
        font-size: 17px;
    }
    .partner-card li p,
    .partner-card li h5 {
        font-size: 11px;
    }
}

@media only screen and (max-width:961px) {
    .partner-studies .partner-card-sections> ul {
        flex-basis: 47%;
    }

    .partner-studies .partner-card-sections> ul:not(:nth-of-type(3n)) {
        margin-right: 0;
    }
    .partner-studies .partner-card-sections> ul:not(:nth-of-type(even)) {
        margin-right: 6%;
    }
    .partner-card li h3 {
        font-size: 24px;
    }
    .partner-card li p,
    .partner-card li h5 {
        font-size: 15px;
    }
}

@media only screen and (max-width:890px) {
    .partner-card li h3 {
        font-size: 22px;
    }
    .partner-card li p .partner-card li p,
    .partner-card li h5 {
        font-size: 12px;
    }
}

@media only screen and (max-width:814px) {
    .partner-card li h3 {
    font-size: 20px;
    }
    .partner-card li p,
    .partner-card li h5 {
        font-size: 12px;
    }
}
@media only screen and (max-width:745px) {
    .partner-card li h3 {
    font-size: 18px;
    }
    .partner-card li p,
    .partner-card li h5 {
        font-size: 11px;
    }
}

@media only screen and (max-width:670px) {
    .partner-studies .partner-card-sections> ul {
        flex-basis: 100%;
    }
    .partner-studies .partner-card-sections> ul:not(:nth-of-type(even)) {
        margin-right: 0;
    }
    .partner-card li h3, .partner-card li h5, .partner-card li p {
        padding: 0 9%;
    }
    .partner-card li h3 {
        font-size: 32px;
    }
    .partner-card li p,
    .partner-card li h5 {
        font-size: 20px;
    }
    .partner-card li h3::before {
        left: 10%;
    }
}

@media only screen and (max-width:616px) {
    .partner-card li h3 {
        font-size: 29px;
    }
    .partner-card li p,
    .partner-card li h5 {
        font-size: 18px;
    }
}

@media only screen and (max-width:558px) {
    .partner-card li h3 {
        font-size: 26px;
    }
    .partner-card li p,
    .partner-card li h5 {
        font-size: 16px;
    }
}

@media only screen and (max-width:500px) {
    .partner-card li h3 {
        font-size: 24px;
    }
    .partner-card li p,
    .partner-card li h5 {
        font-size: 15px;
    }
}

@media only screen and (max-width:480px) {
    .partner-card li h3 {
        font-size: 27px;
    }
    .partner-card li p,
    .partner-card li h5 {
        font-size: 18px;
    }
}

@media only screen and (max-width:434px) {
    .partner-card li h3 {
        font-size: 24px;
    }
    .partner-card li p,
    .partner-card li h5 {
        font-size: 15px;
    }
}

@media only screen and (max-width:385px) {
    .partner-card li h3 {
        font-size: 22px;
    }
    .partner-card li p,
    .partner-card li h5 {
        font-size: 13px;
    }
}

@media only screen and (max-width:352px) {
    .partner-card li h3 {
        font-size: 20px;
    }
    .partner-card li p,
    .partner-card li h5 {
        font-size: 12px;
    }
}

@media only screen and (max-width:320px) {
    .partner-card li h3 {
        font-size: 18px;
    }
    .partner-card li p,
    .partner-card li h5 {
        font-size: 11px;
    }
}

@media only screen and (max-width:320px) {
    .partner-card li h3 {
        font-size: 17px;
    } 
    .partner-card li p,
    .partner-card li h5 {
        font-size: 10px;
    }
}