.footer-container {
  display: flex;
  color: #c4cbf5;
}

.footer-info {
  padding: 4.5% 0;
  flex-basis: 50%;
  background: rgba(25, 89, 173, 1);
  box-shadow: inset 0px 222px 155px #080d27, inset -750px -158px 583px #010728;
}

.footer-info > div {
  max-width: 1920px;
  width: 62%;
  margin: 0 auto;
  display: flex;
  flex-wrap: wrap;
}

.footer-quicklinks h2,
.footer-reachUs h2 {
  padding-bottom: 12%;
  color: var(--TEXT-PRIMARY, #f2f5f7);
  font-family: Poppins;
  font-size: 32px;
  font-style: normal;
  font-weight: 600;
  line-height: 40px; /* 125% */
  letter-spacing: -0.48px;
  text-transform: uppercase;
}
.footer-quicklinks,
.footer-reachUs {
  flex-basis: 48.5%;
}
.footer-quicklinks {
  margin-right: 3%;
}
.footer-quicklinks ul {
  display: flex;
  flex-direction: column;
}
.footer-quicklinks ul li,
.footer-reachUs ul li {
  line-height: 45px;
}
.footer-quicklinks ul li a {
  text-transform: capitalize;
}
.footer-quicklinks ul li a,
.footer-reachUs ul li a {
  display: inline;
  color: #c4cbf5;
  font-family: Poppins;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
}

.footer-reachUs ul {
  display: flex;
  flex-direction: column;
}
.footer-reachUs li {
  display: flex;
}
.footer-reachUs li a {
  color: #c4cbf5;
}
.footer-reachUs li:first-child figure {
  width: 26px;
  padding-right: 8px;
}

.footer-reachUs li:last-child figure {
  padding-right: 8px;
}
.copyright-policy {
  padding-top: 15%;
  flex-basis: 100%;
  display: flex;
}
.copyright-policy li {
  font-size: 12px;
  font-weight: 600;
  line-height: 18px; /* 150% */
  letter-spacing: 0.36px;
}

.copyright-policy li:first-child {
  flex-basis: 100%;
}

.copyright-policy li a {
  color: #c4cbf5;
  font-weight: 600;
  line-height: 18px; /* 150% */
  letter-spacing: 0.36px;
}

.copyright-policy li:last-child::before ,
.copyright-policy li:nth-child(2)::before {
  content: "\2022";
  color: #06a9ca;
  font-weight: bold;
  display: inline-block;
  margin-right: 3px;
}
 
.copyright-policy li:nth-child(2) {
  margin-right: 2%;
}

.copyright-policy li:nth-child(2),
.copyright-policy li:last-child {
  flex-basis: 24%;
}

.footer-logo-socialIcons {
  padding: 2.8% 0;
  flex-basis: 50%;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  align-items: center;
  background: linear-gradient(180deg, #1B275A 0%, #0E1434 100%);
  position: relative;
}
.footer-logo-socialIcons figure {
  width: 386px;
}
.footer-logo-socialIcons p {
  padding-top: 10px;
  display: flex;
  flex-direction: column;
  color: #f2f5f7;
  text-align: center;
  font-family: Poppins;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
}

.footer-logo-socialIcons .links img {
  width: 26px;
  height: 26px;
}

.footer-logo-socialIcons ul {
  width: 36%;
  position: absolute;
  top: 73%;
  left: 29%;
  display: flex;
  justify-content: space-between;
}

.links {
  position: relative;
  left: 0;
}
.footer-logo-socialIcons ul li a {
  position: absolute;
  padding: 22px;
  border-radius: 80px;
  border: 2px solid rgba(25, 89, 173, 0.25);
  background: #0a122f;
  
}

 @media screen and (min-width: 580px) {
  .footer-quicklinks ul li a:hover,
  .footer-reachUs ul li a:hover,
  .copyright-policy li a:hover {
    color: #00b3cc;
    transition: 0.3s ease-in-out;
  }
  .footer-logo-socialIcons ul li a:hover {
    background: #010728;
    padding: 25px;
    border: 2px solid var(--Primary-color, #06a9ca);
    transition: 0.3s ease-in-out;
   } 
}

@media screen and (max-width: 580px) {
  .footer-logo-socialIcons ul li a {
    padding: 16px;
  }
  .footer-logo-socialIcons ul li a:focus {
    border: 2px solid var(--Primary-color, #06a9ca);
    transition: 0.3s ease-in-out;

  }
}

@media only screen and (max-width: 1700px) {

  /* .copyright-policy {
    padding-top: 15%;
    flex-basis: 100%;
    display: flex;
}

  .footer-logo-socialIcons ul {
    width: 36%;
    position: absolute;
    top: 73%;
    left: 29%;
    display: flex;
    justify-content: space-between;
} */

.footer-logo-socialIcons figure {
  width: 350px;
}
}

@media only screen and (max-width: 1560px) {
  .copyright-policy li a {
    font-size: 11px;
  }
}

@media only screen and (max-width: 1450px) {
  .footer-logo-socialIcons ul {
    width: 50%;
    left: 20%;
  }
  .copyright-policy li a {
    font-size: 10px;
  }
  .copyright-policy li a {
    font-size: 9px;
  }
}

@media only screen and (max-width: 1325px) {
  .footer-quicklinks h2, 
  .footer-reachUs h2{
    font-size: 24px;
  }
  .footer-quicklinks ul li a, 
  .footer-reachUs ul li a {
    font-size: 14px;
  }
  .footer-logo-socialIcons figure {
    width: 300px;
}
}

@media only screen and (max-width: 1229px) {
  .footer-info > div {
    width: 75%;
  }
}

@media only screen and (max-width: 1024px) {
  .footer-info > div {
    flex-direction: column;
  } 
  .footer-quicklinks {
    margin-right: 0;
  }
  .copyright-policy li:first-child {
    flex-basis: 100%;
    font-size: 9px;
  }
  .copyright-policy li:nth-child(2), 
  .copyright-policy li:last-child{
    flex-basis: 30%;
  }
  .copyright-policy li:nth-child(2) {
    margin-right: 0;
  }
  .footer-logo-socialIcons {
    justify-content: center;
  }
  .footer-logo-socialIcons ul {
    width: 59%;
    top: 75%;
    left: 17%;
  }
  .footer-logo-socialIcons ul li a {
    padding: 16px;
  }
  .footer-logo-socialIcons p {
    font-size: 18px;
  }
  .footer-quicklinks ul li,
  .footer-reachUs ul li {
    line-height: 37px;
  }
  .footer-quicklinks h2,
  .footer-reachUs h2 {
    padding-bottom: 5%;
  }
  .copyright-policy {
    flex-basis: 70%;
    padding-top: 10%;
  }
  .footer-quicklinks {
    padding-bottom: 3%;
  }
  .footer-logo-socialIcons figure {
    width: 79%;
  }
  .footer-quicklinks ul li a,
  .footer-reachUs ul li a {
    font-size: 16px;
  }
  .footer-logo-socialIcons {
    justify-content: flex-start;
    padding-top: 5%;
}
}

@media only screen and (max-width: 930px) {
  .footer-logo-socialIcons p {
    font-size: 18px;
  }
  .footer-logo-socialIcons ul li a {
    padding: 10px;
  }
  .footer-logo-socialIcons ul {
    left: 15%;
  }
}

@media only screen and (max-width: 820px) {
  .copyright-policy li:first-child,
  .copyright-policy li a{
    font-size: 8px;
  }
}

@media only screen and (max-width: 768px) {
  .footer-container {
    flex-direction: column;
    position: relative;
  }
  .footer-info {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .footer-info > div {
    width: 70%;
    flex-direction: row;
  }
  .copyright-policy {
    position: absolute;
    bottom: 5%;
    display: flex;
    flex-direction: row;
    z-index: 2;
  }
  .footer-logo-socialIcons {
    padding-bottom: 35%;
  }
  .footer-logo-socialIcons ul {
    top: 66%;
    left: 18%;
  }
  .footer-logo-socialIcons p {
    font-size: 24px;
  }
  .copyright-policy {
    width: 70%;
    padding: 0;
    bottom: 10%;
  }
  .copyright-policy li:first-child, .copyright-policy li a {
    font-size: 16px;
    text-align: center;
  }
  .footer-logo-socialIcons figure {
    width: 70%;
  }
}

@media only screen and (max-width: 630px) {
  .footer-info{
    box-shadow: inset -207px 0px 155px #080d27, inset 158px -750px 583px #1b275a;
  }
  .footer-quicklinks h2{
    font-size:24px ;
  }
  .footer-reachUs h2{
    font-size: 24px;
  }
  .footer-info > div {
    width: 70%;
    margin: auto;
    display: grid;
    grid-template-columns: repeat(2,1fr);
  }

  .footer-logo-socialIcons .figure {
    width: 55%;
  }
  .footer-logo-socialIcons p {
    font-size: 20px;
  }
  .copyright-policy li:first-child, .copyright-policy li a {
    font-size: 13px;
  }
  .footer-logo-socialIcons .links img {
    width: 24px;
    height: 24px;
  }

  .copyright-policy {
    position: absolute;
    flex-direction: row;
    z-index: 2;
    bottom: 8%;
  }
  .copyright-policy li {
    line-height: 25px;
    font-weight: 100;
    font-size: 10px;
  }
  .copyright-policy li:last-child {
    padding-left: 0;
  }
  .copyright-policy li:nth-child(2)::before {
    padding: 0;
  }
  
  .copyright-policy li:last-child::before {
    padding-right: 0;
  }
  .footer-logo-socialIcons figure {
    width: 70%;
}
}

@media only screen and (max-width: 485px) {
  .footer-logo-socialIcons {
    padding-bottom: 40%;
  }
  .footer-logo-socialIcons ul {
    top: 64%;
  }
  .copyright-policy li:first-child, 
  .copyright-policy li a {
    font-size: 10px;
  }
  .footer-logo-socialIcons p {
    font-size: 17px;
  }
  .footer-quicklinks h2,
  .footer-reachUs h2 {
    font-size: 16px;
  }
  .footer-quicklinks ul li a, .footer-reachUs ul li a {
    font-size: 12px;
  }
  .copyright-policy {
    width: 90%;
    padding: 0;
    bottom: 7%;
    left: 6%;
    /* padding-top: 5%; */
}
}

@media only screen and (max-width: 410px) {
  .footer-logo-socialIcons p {
    font-size: 17px;
  }
  .footer-logo-socialIcons ul {
    width: 50%;
    top: 63%;
    left: 19%;
  }
  .footer-logo-socialIcons ul li a {
    padding: 10px 12px;
  }
  .footer-logo-socialIcons .links img {
    width: 22px;
  }
  .copyright-policy li:first-child, 
  .copyright-policy li a {
    font-size: 9px;
  }
  .footer-logo-socialIcons p {
    font-size: 15px;
  }
}

@media only screen and (max-width: 365px) {
  .footer .wrapper .footer_container .footer_header span {
    font-size: 18px;
  }
  .footer-logo-socialIcons ul li a {
    padding: 3px 8px;
  }
  .footer-logo-socialIcons .links img {
    width: 16px;
    height: 16px;
  }
  .copyright-policy li:first-child, .copyright-policy li a {
    font-size: 7px;
  }
}

@media only screen and (max-width: 320px) {
  .footer-quicklinks h2, .footer-reachUs h2,.footer-logo-socialIcons p {
    font-size: 12px;
  }
  .footer-quicklinks ul li, .footer-reachUs ul li {
    line-height: 20px;
  }
  .footer-quicklinks ul li a, .footer-reachUs ul li a {
    font-size: 9px;
  }
  .footer-reachUs li:first-child figure,
  .footer-reachUs li figure {
    width: 20px;
  }
  .copyright-policy li:first-child, .copyright-policy li a {
    font-size: 6.5px;
  }
}

.footer-display-none {
  display: none;
}

.footer-logo-socialIcons .X {
  display: none;
}